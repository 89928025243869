import type { Reducer } from 'react';
import { useEffect, useReducer } from 'react';

import type { AutocompleteApi } from '../types';

type State<V> = {
  items: V[];
  loading: boolean;
};

type ResponseAction<V> = {
  type: 'RES';
  payload: V[];
};

type Action<V> = 'REQ' | 'ERROR' | 'RESET' | ResponseAction<V>;

export const AUTOCOMPLETE_DEBOUNCE_TIME = 250;

const initialApiState = { items: [], loading: false };

export const useAutocompleteApi = <V>(
  search: string,
  webApi: AutocompleteApi<V>,
  enabled: boolean,
  onChange?: (state: State<V>, prev: State<V>) => void,
  limit = 20
) => {
  const [state, dispatch] = useReducer<Reducer<State<V>, Action<V>>>((s, a) => {
    const type = typeof a === 'string' ? a : a.type;

    let nextState = s;

    switch (type) {
      case 'REQ':
        nextState = {
          items: initialApiState.items,
          loading: true,
        };
        break;
      case 'RES':
        nextState = {
          items: (a as ResponseAction<V>).payload.slice(0, limit),
          loading: false,
        };
        break;
      case 'ERROR':
      case 'RESET':
        nextState = initialApiState;
        break;
    }

    if (onChange) {
      onChange(nextState, s);
    }

    return nextState;
  }, initialApiState);

  useEffect(() => {
    dispatch('RESET');
  }, [enabled]);

  useEffect(() => {
    if (!enabled) return;

    let aborted = false;
    let loadingTimer;

    //I'ts better to show the loader instead of no-results
    if (state.items === initialApiState.items) {
      dispatch('REQ');
    } else {
      /**
       * Delaying the loading state when there are items
       * improves the update performance because dom is reused
       */
      loadingTimer = setTimeout(() => {
        dispatch('REQ');
      }, 1000);
    }

    const doRequest = () => {
      webApi(search)
        .then((payload) => {
          if (aborted) return;

          dispatch({
            type: 'RES',
            payload,
          });
        })
        .catch(() => {
          if (aborted) return;

          dispatch('ERROR');
        })
        .then(() => {
          clearTimeout(loadingTimer);
        });
    };

    const timer = setTimeout(doRequest, AUTOCOMPLETE_DEBOUNCE_TIME);

    //Abort
    return () => {
      aborted = true;
      clearTimeout(timer);
      clearTimeout(loadingTimer);
    };
  }, [search]);

  return state;
};
