import type { PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';
import { useSetAtom } from 'jotai';

import { hasChecklistItemAtom } from '../atoms';

import { useGeographySearchAtom } from '../hooks/useGeographySearchAtom';
import { useGetEntityWithChildren } from '../hooks/useGetEntityWithChildren';

import type { PlaceSearchGeography } from 'src/libs/geography';
import { PepitaButton } from 'src/libs/ui/pepita-button';

import type { BaseEntityWithChildren } from 'src/types/geography';

import { isMetro } from '../utils/entity';

import { CheckListAccordion } from './CheckListAccordion';
import { ResultCountButton } from './ResultCountButton';

interface PlaceCheckListProps extends PropsWithChildren {
  customClass?: string;
  onChange?: (geographySearch: PlaceSearchGeography) => void;
}

function PlaceCheckListRoot({
  customClass,
  onChange,
  children,
}: PlaceCheckListProps) {
  const { trans } = useTranslations();
  const [geographySearch] = useGeographySearchAtom();
  const setHasChecklistItem = useSetAtom(hasChecklistItemAtom);

  const [checklistData, setChecklistData] = useState<BaseEntityWithChildren[]>(
    []
  );

  const city = useGetEntityWithChildren();

  useEffect(() => {
    setChecklistData(city?.children?.length ? city.children : []);
    setHasChecklistItem(Boolean(city?.children?.length));
  }, [city, setHasChecklistItem]);

  const headerText: string =
    geographySearch && isMetro(geographySearch.value[0])
      ? trans('lbl_metro')
      : trans('lbl_zones');

  return geographySearch && checklistData.length > 0 && city ? (
    <div
      data-cy="zones-autocomplete-list"
      className={clsx('nd-autocomplete__list', 'is-open', customClass)}
    >
      <div className="nd-autocomplete__listHead">
        <span className="nd-autocomplete__listHeadTitle">{headerText}</span>

        <PepitaButton variant="link">
          {isMetro(geographySearch.value[0])
            ? trans('search_map_header_metro_title')
            : trans('search_map_header_select_place_title')}
        </PepitaButton>
      </div>

      <CheckListAccordion
        checklistData={checklistData}
        city={city}
        onChange={onChange}
      />

      {children}
    </div>
  ) : null;
}

export const PlaceCheckList = Object.assign(PlaceCheckListRoot, {
  ResultCountButton,
});
