import { type RefObject, useEffect, useMemo } from 'react';
import type { LatLngLiteral } from '@pepita-canary/leaflet';
import { useTranslations } from '@pepita-react/i18n';
import { useRouter } from 'next/router';

import { useAutocomplete } from 'src/components/Autocomplete';

import { useGeographySearchAtom } from '../hooks/useGeographySearchAtom';

import {
  isPlaceSearchGeography,
  isPlaceSearchGeographyWithChildren,
  type PlaceSearchGeography,
} from 'src/libs/geography';

import type { BaseEntityWithChildren } from 'src/types/geography';
import type { Language } from 'src/types/translations';

import { getPlaceAutocompleteApi } from '../utils/autocompleteApi';
import { buildPlaceSearchGeography } from '../utils/geography';

import { useIsSearchFeaturesEnabled } from '../context';

const defaultCenter = {
  lat: 41.902715,
  lng: 12.496245,
};

interface UsePlaceAutocompleteArgs {
  inputRef: RefObject<HTMLInputElement>;
  onSetGeography:
    | ((
        isGeographyWithChildren: boolean,
        placeSearchGeography: PlaceSearchGeography
      ) => void)
    | undefined;
  onRemove: (() => void) | undefined;
}

export const usePlaceAutocomplete = ({
  inputRef,
  onSetGeography,
  onRemove,
}: UsePlaceAutocompleteArgs) => {
  const { trans } = useTranslations();
  const { locale } = useRouter() as { locale: Language };

  const [geographySearch, setGeographySearch] = useGeographySearchAtom();

  // We get the center of the last one place selected or a default value
  const autocompleteProximity: LatLngLiteral = useMemo(() => {
    return geographySearch &&
      isPlaceSearchGeography(geographySearch) &&
      geographySearch.value[0]?.center
      ? geographySearch.value[0].center
      : defaultCenter;
  }, [geographySearch]);

  const isSearchFeaturesEnabled = useIsSearchFeaturesEnabled();

  const autocomplete = useAutocomplete<BaseEntityWithChildren>({
    input: inputRef,
    minLength: 2,
    defaultValue: geographySearch?.value as BaseEntityWithChildren[],
    autocompleteApi: (query) =>
      getPlaceAutocompleteApi(
        autocompleteProximity,
        query,
        locale,
        trans,
        isSearchFeaturesEnabled
      ),
    withBackspace: Boolean(geographySearch),
    reducer: (_, action) => {
      switch (action.type) {
        case 'ADD_ITEM':
          return [action.item];
        case 'REMOVE_ITEM':
          onRemove?.();
          break;
      }

      return action.changes || [];
    },
  });

  const { values, reset, inFocus, search } = autocomplete;

  const value = values.length > 0 ? values[0] : null;

  // Get text to show inside the input...
  // If it is in focus we show the string as typed by the user
  // Otherwise we show an empty string (in case of selection we already
  // have the geography tag to show)
  const inputText = inFocus ? search : '';

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.value = inputText;
  }, [inputRef, inputText]);

  /*
    When a value is selected by the autocomplete we need to:
    - update the geographyData atom with the new selected value;
    - open the place checklist if the geographyData matches some conditions.
  */
  useEffect(() => {
    const placeSearchGeography = value
      ? buildPlaceSearchGeography([value])
      : null;

    if (placeSearchGeography) {
      setGeographySearch(placeSearchGeography);

      if (onSetGeography) {
        onSetGeography(
          isPlaceSearchGeographyWithChildren(placeSearchGeography),
          placeSearchGeography
        );
      }
    }
  }, [setGeographySearch, onSetGeography, value]);

  /*
    When the geographyData is set to null (the user has removed the autocomplete
    tag), we need to reset the autocomplete value to null.
  */
  useEffect(() => {
    if (!geographySearch) {
      reset();
    }
  }, [geographySearch, reset]);

  return { autocomplete, value };
};
