import type { LatLngLiteral } from '@pepita-canary/leaflet';
import type { AddressAutocompleteValue } from 'src/repository/address-autocomplete/types';
import type { RawGeographyAutocompleteItem } from 'src/repository/geography-autocomplete/api';

import { getProductConfig } from 'src/config/product';

import { ENTITY_TYPE } from 'src/types/geography';
import type { Language } from 'src/types/translations';
import type { GeographySearchFeatureToggles } from '../types';

import { http } from 'src/utils/client/http';
import type { Trans } from 'src/utils/i18nFormatters';

import { computeEntryLabel } from './labels';

export const getPlaceAutocompleteApi = (
  proximity: LatLngLiteral,
  query: string,
  language: Language,
  trans: Trans,
  isGeographyFeatureEnabled: (
    feature: keyof GeographySearchFeatureToggles
  ) => boolean
) =>
  http('/api-next/geography/autocomplete', {
    searchParams: {
      macrozones: 1,
      proximity: `${proximity.lat},${proximity.lng}`,
      microzones: 1,
      min_level: isGeographyFeatureEnabled('metroSearch') ? 9 : 5,
      query,
      withRegions: isGeographyFeatureEnabled('regionSearch') || undefined,
      withCountries: isGeographyFeatureEnabled('countrySearch') || undefined,
      international:
        isGeographyFeatureEnabled('internationalSearch') || undefined,
      __lang: language,
    },
  })
    .json<RawGeographyAutocompleteItem[]>()
    .then((items) =>
      items.map((value) => {
        const { macrozones, ...rest } = value;

        return {
          ...rest,
          ...computeEntryLabel(
            [value],
            isGeographyFeatureEnabled('labelsForCountry'),
            trans
          ),
          originalLabel: value.label,
          icon: value.type === ENTITY_TYPE.metro ? 'metro' : 'marker',
          hasMacrozones: Boolean(macrozones),
        };
      })
    );

const key = getProductConfig('geocodeAddressApiKey');
const country = getProductConfig('countryCode');
const defaultLocale = getProductConfig('defaultLocale');
const language = `${defaultLocale}-${country}`;

export const addressAutocompleteApi = (
  proximity: LatLngLiteral,
  query: string
) =>
  http('/api-next/address/autocomplete/', {
    searchParams: {
      q: query,
      proximity: `${proximity.lat},${proximity.lng}`,
      language,
      key,
      country,
    },
  }).json<AddressAutocompleteValue[]>();
