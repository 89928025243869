import type { PropsWithChildren, ReactNode } from 'react';
import { clsx } from 'clsx';
import Link from 'next/link';

import type { PepitaIconProps } from 'src/libs/ui/pepita-icon';

import { HeaderStyles } from './index';

export type HeaderItemProps = {
  customClass?: string;
  icon?: PepitaIconProps;
  text?: ReactNode;
  link?: {
    url: string;
    target?: '_blank' | '_self';
  };
  props?: { [key: string]: any };
  onClick?: () => void;
  visibleOnScreen?: 'small' | 'medium';
  isClientSideNavigation?: boolean;
  isNew?: boolean;
};

export function HeaderItem({
  customClass,
  link,
  props,
  children,
  onClick,
  visibleOnScreen,
  isClientSideNavigation,
}: PropsWithChildren<HeaderItemProps>) {
  if (!children) return null;

  const anchor = (
    <a
      className={clsx(
        HeaderStyles.link,
        visibleOnScreen && `${HeaderStyles.link}--${visibleOnScreen}Visible`,
        customClass && customClass
      )}
      onClick={onClick}
      onKeyDown={(event) => {
        if (onClick && (event.key === 'Enter' || event.keyCode === 13)) {
          onClick();
        }
      }}
      href={!isClientSideNavigation ? link?.url : undefined}
      {...props}
    >
      {children}
    </a>
  );

  if (link && link.url) {
    return isClientSideNavigation ? (
      <Link href={link.url} passHref legacyBehavior>
        {anchor}
      </Link>
    ) : (
      anchor
    );
  }

  return (
    <div
      className={clsx(
        HeaderStyles.item,
        visibleOnScreen && `${HeaderStyles.item}--${visibleOnScreen}Visible`,
        customClass && customClass
      )}
      onClick={onClick}
      role="button"
      onKeyDown={(event) => {
        if (
          (event.key === 'Enter' || event.keyCode === 13) &&
          Boolean(onClick)
        ) {
          onClick();
        }
      }}
      tabIndex={onClick && 0}
      {...props}
    >
      {children}
    </div>
  );
}
